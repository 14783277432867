import classNames from "classnames";
import { useEffect, useState, useRef } from "react";
import Lottie from "lottie-react";
import { Loading, SentenceBookmarkButton } from "../../../../components";
import {EventLogs, PronunciationErrors, PronunciationResultType} from "../../../../components/Constants";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import useSpeechStudio from "../../../../hooks/useSpeechStudio";
import useWindowSize from "../../../../hooks/useWindowSize";
import ScoreCard from "../../../Exercises/components/ScoreCard";
import { SpeakingLabCorrect, SpeakingLabPlaybackOptions, SpeakingLabWrong } from "./components";
import { removePunctuation } from "../../../../utils";
import useAnalytics from "../../../../hooks/useAnalytics";

const SpeakingLabPronunciation = ({ exerciseData, onNext, speakSentence, isSpeakingComplete, dynamicPassingScore, ttsInfo, defaultOptions, isLastExercise }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const analytics = useAnalytics();
    const {
        handleSpeechStudioRecording,
        handleResetStates,
        handleResetIsFirstRecordingCompleted,
        showAnimation,
        isFirstRecordingCompleted,
        speechStudioLoading,
        speechStudioResult,
        isErrorOccured,
        audioKey
    } = useSpeechStudio({
        contentId: exerciseData?.exercise?.sentenceId,
        memberId: member?.MemberId,
        source: PronunciationResultType.SPEAKING_LAB
    });
    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    // Doğru veya yanlış cevap durumunu tutan state
    const [isCorrect, setIsCorrect] = useState(false);
    // Yanlış cevap sayısını tutan yeni state
    const [wrongAttempts, setWrongAttempts] = useState(0);
    // Tooltip için state'ler
    const [showRecordingTooltip, setShowRecordingTooltip] = useState(false);
    // Kayıt zamanlayıcısı için ref
    const recordingTimerRef = useRef(null);
    // Kayıt kontrolü için ref
    const isRecordingRef = useRef(false);

    // Yanlış cevap componentine gönderildiğinde cümleyi tekrar seslendirmek için kullanılır.
    const handlePronunciation = async (sentence) => {
        window.scrollTo(0, 0);
        isRecordingRef.current = true;
        handleSpeechStudioRecording(removePunctuation(sentence), member?.MemberId);
        handleResetStates();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_PRONOUNCE_CLICKED);

        // Önceki zamanlayıcıyı temizle
        if (recordingTimerRef.current) {
            clearTimeout(recordingTimerRef.current);
        }
        // 15 saniye sonra tooltip'i göster ve kaydı durdur
        recordingTimerRef.current = setTimeout(() => {
            if (isRecordingRef.current) { // Eğer hala kayıt devam ediyorsa
                setShowRecordingTooltip(true);
                isRecordingRef.current = false;
                handleResetStates();
                // MediaRecorder'ı durdur
                if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                    window.mediaRecorder.stop();
                }
                // Mikrofon erişimini kapat
                if (window.mediaStream && window.mediaStream.getTracks) {
                    window.mediaStream.getTracks().forEach(track => track.stop());
                }
            }
        }, 15000);
    }

    // Cümleyi tekrar seslendirmek için kullanılır.
    const handleTryAgain = async () => {
        handleResetIsFirstRecordingCompleted();
        handleResetStates();
        window.scrollTo(0, 0);
        await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);

        analytics.useAnalytics(EventLogs.SPEAKING_LAB_TRY_AGAIN_CLICKED,{
            source: "pronounce"
        });
    }

    // Egzersize devam etmek için kullanılır.
    const handleContinue = () => {
        handleResetStates();
        handleResetIsFirstRecordingCompleted();
        onNext();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
            source: "pronounce"
        });
    }

    // Ses kaydını durdurmak için yardımcı fonksiyon
    const stopRecording = () => {
        if (isRecordingRef.current) {
            isRecordingRef.current = false;
            handleResetStates();
            // MediaRecorder'ı durdur
            if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                window.mediaRecorder.stop();
            }
            // Mikrofon erişimini kapat
            if (window.mediaStream && window.mediaStream.getTracks) {
                window.mediaStream.getTracks().forEach(track => track.stop());
            }
        }
    };

    // Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
    // Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
    // sayfayı 200 piksel aşağı kaydırır.
    useEffect(() => {
        if (speechStudioResult && !speechStudioLoading) {
            window.scrollTo({ 
                top: document.body.scrollHeight,
                behavior: 'smooth' 
            });
        }
    }, [speechStudioResult, speechStudioLoading]);

    /*
        Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
        Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
        `isCorrect` değişkenini `speechStudioResult.Score` değişkeni ile `dynamicPassingScore` değişkenini karşılaştırarak günceller.
    */
    useEffect(() => {
        const playFeedbackSound = async (isCorrect) => {
            isCorrect
                ? await speakSentence('', '', 'correct-answer')
                : await speakSentence('', '', 'wrong-answer');
        };

        if(speechStudioResult && !speechStudioLoading){
            const passedScore = speechStudioResult.pron_score >= dynamicPassingScore;
            const hasErrors = speechStudioResult.words.some(word => {
                return word.error_type === PronunciationErrors.MISPRONUNCIATION || 
                        word.error_type === PronunciationErrors.OMISSION;
            });
    
            const isCorrect = !hasErrors && passedScore;

            playFeedbackSound(isCorrect);        
            setIsCorrect(isCorrect);

            // Eğer cevap yanlışsa, wrongAttempts'i artır
            if (!isCorrect) {
                setWrongAttempts(prevAttempts => prevAttempts + 1);
            }
        }
    }, [speechStudioResult, speechStudioLoading, dynamicPassingScore]);

    // Bu useEffect hook'u, exerciseData veya speakSentence değiştiğinde çalışır.
    // Eğer exerciseData.exercise içinde bir cümle varsa, bu cümleyi seslendirir.
    // Bu, kullanıcıya egzersiz cümlesini otomatik olarak dinletmek için kullanılır.
    useEffect(() => {
        const speakExerciseSentence = async () => {
            await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
        };

        if (Object.keys(ttsInfo).length > 0) speakExerciseSentence();
    }, []);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_IMPRESSION);
    }, []);

    // speechStudioResult değiştiğinde kayıt durumunu güncelle
    useEffect(() => {
        if (speechStudioResult) {
            isRecordingRef.current = false;
        }
    }, [speechStudioResult]);

    // Tooltip'i 2 saniye sonra gizle
    useEffect(() => {
        let tooltipTimer;
        if (showRecordingTooltip) {
            tooltipTimer = setTimeout(() => {
                setShowRecordingTooltip(false);
            }, 4000);
        }
        return () => clearTimeout(tooltipTimer);
    }, [showRecordingTooltip]);

    // Component unmount olduğunda zamanlayıcıyı ve kaydı temizle
    useEffect(() => {
        return () => {
            if (recordingTimerRef.current) {
                clearTimeout(recordingTimerRef.current);
            }
            if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                window.mediaRecorder.stop();
            }
            if (window.mediaStream) {
                window.mediaStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    return (
        <>
            <p className="my-5 text-center">
                {strings.speaking_lesson.speaking_lab.pronounce_sentence_title}
            </p>
            <div className="gray-section min-h-[330px] flex flex-col">
                <div className="w-full flex justify-end">
                    <SentenceBookmarkButton 
                        initialIsSaved={exerciseData?.exercise?.isSavedSentence} 
                        sentenceId={exerciseData?.exercise?.sentenceId}
                        logEvent={{
                            true: EventLogs.SPEAKING_LAB_ADDED_TO_LEARNING_LIST,
                            false: EventLogs.SPEAKING_LAB_REMOVED_TO_LEARNING_LIST
                        }}
                    />
                </div>
                <div className="flex flex-col gap-2 justify-start">
                    <p className="text-center font-bold text-[20px]">{exerciseData?.exercise?.sentence}</p>
                </div>

                <div className="flex-grow"></div>

                <div className="flex flex-col gap-3">
                    {exerciseData?.exercise?.hint ?
                        <p className="text-[14px]">
                            <span className="font-bold">{strings.speaking_lesson.speaking_lab.sentence_hint}:{" "}</span>
                            {exerciseData?.exercise?.hint}
                        </p>
                        : <p className="text-[14px]">
                            {exerciseData?.exercise?.sentenceTranslation}
                        </p>
                    }
                    {(isFirstRecordingCompleted && !showAnimation) && 
                        <SpeakingLabPlaybackOptions 
                            systemPronunceButtonClick={() => speakSentence(
                                exerciseData?.exercise?.sentence, 
                                process.env.REACT_APP_LANG_EN_CODE, 
                                exerciseData?.exercise?.sentenceId
                            )}
                            memberAudioUrl={speechStudioResult?.audioUrl}
                            audioKey={audioKey}
                        />
                    }
                    <div className="flex gap-2 justify-center items-center mb-2">
                        <div className="relative group">
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-incompleted rounded-full hover:bg-incompleted/70", {
                                    "opacity-70 pointer-events-none" : !isSpeakingComplete,
                                })}
                                disabled={!isSpeakingComplete}
                                onClick={() => speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId)}
                            >
                                <span className="material-symbols-outlined text-base-text text-[26px]">replay</span>
                            </button>
                            {isSpeakingComplete &&
                                <div className="tooltip-top -top-[38px]">
                                    {strings.speaking_lesson.speaking_lab.voice_again}
                                </div>
                            }
                        </div>
                        <div className={classNames("relative group", {
                            "group-active": showRecordingTooltip
                        })}>
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-incompleted rounded-full hover:bg-incompleted/70", {
                                    "opacity-70 pointer-events-none" : speechStudioLoading || !isSpeakingComplete,
                                })}
                                disabled={speechStudioLoading || !isSpeakingComplete}
                                onClick={() => handlePronunciation(exerciseData.exercise?.sentence)}
                            >
                                <span className="material-symbols-outlined text-base-text text-[28px]">mic</span>
                                {showAnimation ? (
                                    <div className="absolute inset-0 rounded-full overflow-hidden animate-overlay">
                                        <Lottie 
                                            animationData={defaultOptions.animationData} 
                                            loop={true}
                                            autoplay={true}
                                            style={{ height: 56, width: 56 }}
                                        />
                                    </div>
                                ) : null}
                            </button>
                            {showRecordingTooltip ? (
                                <div className="tooltip-top -top-[58px]">
                                    {strings.speaking_lesson.speaking_lab.are_you_there}
                                </div>
                            ) : (
                                isSpeakingComplete && 
                                    <div className="tooltip-top -top-[38px]">
                                        {strings.video_practice.word_card.word_pronunciation}
                                    </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {speechStudioLoading && <Loading text={strings.speaking_lesson.speaking_lab.results_loading} />}

            {speechStudioResult && !speechStudioLoading &&
                <div className="flex items-center justify-center">
                    <ScoreCard result={speechStudioResult} classnames="mt-5 max-w-[500px]"/>
                </div>
            }

            {isErrorOccured && 
                <SpeakingLabWrong 
                    textInfoTitle={strings.speaking_lesson.speaking_lab.pronunce_error_text}
                    buttonClick={handleTryAgain} 
                />
            }

            {(speechStudioResult && !speechStudioLoading && !isErrorOccured) && (
                <>
                    {isMobile && <div className="h-[180px]"></div>}
                    {isCorrect 
                        ? <SpeakingLabCorrect
                            textInfo={speechStudioResult?.words} 
                            buttonClick={handleContinue}
                            buttonText={isLastExercise ? strings.video_practice.complete : strings.market.information.button}
                        />
                        : <SpeakingLabWrong
                            textInfo={speechStudioResult?.words} 
                            buttonClick={handleTryAgain}
                            skipButtonCondition={wrongAttempts >= 3}
                            skipButtonClick={onNext}
                        />
                    }
                </>
            )}
        </>
    );
}

export default SpeakingLabPronunciation;