import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import record from "../../../../assets/animation/record.json";
import { ModalOverlay, ModalTwoButtons } from '../../../../components';
import {EventLogs, ExerciseCode, ExerciseSubCode, SpeakingLabResultType} from '../../../../components/Constants';
import { useLocalization } from '../../../../hooks/useLocalization';
import { url } from '../../../../routes/utility';
import { AddMemberSpeakingLab } from '../../../../services/SpeakingLab/SpeakingLabService';
import { closeModal, openModal } from '../../../../utils';
import { ExerciseQuestionStep } from '../../../Exercises/components';
import { SpeakingLabCard, SpeakingLabCreateSentence, SpeakingLabFillInTheBlank, SpeakingLabPronunciation } from '../index';
import useAnalytics from "../../../../hooks/useAnalytics";

const SpeakingLabFlow = (props) => {

  const {
    exercises, 
    speakSentence, 
    isSpeakingComplete, 
    dynamicPassingScore,
    onExerciseComplete,
    exerciseData
  } = props;

  const strings = useLocalization();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const modalRef = useRef();
  const modalOverlayRef = useRef();

  const [quitExerciseButtonLoading, setQuitExerciseButtonLoading] = useState(false);

  // Lottie animasyonu için default seçenekler
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: record,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // Mevcut egzersizi exercises dizisinden bulur
  const getCurrentExercise = useCallback(() => {
    return exercises[exerciseData?.currentExerciseIndex];
  }, [exercises, exerciseData?.currentExerciseIndex]);

  // Bir sonraki egzersize geçiş yapar
  const handleNext = useCallback(() => {
    onExerciseComplete(exerciseData?.currentExerciseIndex);
  }, [exerciseData?.currentExerciseIndex, onExerciseComplete]);

  // TTS bilgilerini belirlemek için kullanılan fonksiyon
  const determineTtsInfo = useCallback((exercise) => {
    if(exercise?.exerciseSubCode) {
      const translationType = exercise?.exerciseSubCode === ExerciseSubCode.TTS_IN_LOCAL
          ? process.env.REACT_APP_LANG_AZ_CODE
          : process.env.REACT_APP_LANG_EN_CODE;

      const textId = exercise?.exerciseSubCode === ExerciseSubCode.TTS_IN_LOCAL
          ? exercise?.sentenceTranslationId
          : exercise?.sentenceId;

      const sentence = exercise?.exerciseSubCode === ExerciseSubCode.TTS_IN_LOCAL
          ? exercise?.sentenceTranslation
          : exercise?.sentence;

      return {
          language: translationType,
          textId: textId,
          sentence: sentence
      };
    }
  }, []);

  const renderExercise = useCallback((exercise) => {
    const ttsInfo = determineTtsInfo(exercise?.exercise);

    if (exercise.isCard) {
      return <SpeakingLabCard isFirstSequence={exerciseData?.currentExerciseIndex === 0} cardData={exercise.card} onNext={handleNext} />;
    }

    const commonProps = {
      exerciseData: exercise,
      onNext: handleNext,
      speakSentence,
      isSpeakingComplete,
      dynamicPassingScore,
      ttsInfo,
      defaultOptions,
      isLastExercise: exerciseData?.currentExerciseIndex + 1 >= exercises.length
    };

    switch (exercise.exercise.exerciseCode) {
      case ExerciseCode.PRONUNCIATION:
        return <SpeakingLabPronunciation {...commonProps} />;
      case ExerciseCode.FILL_IN_THE_BLANKS:
        return <SpeakingLabFillInTheBlank {...commonProps} />;
      case ExerciseCode.DRAG_AND_DROP:
        return <SpeakingLabCreateSentence {...commonProps} />;
      default:
        return null;
    }
  }, [exerciseData?.currentExerciseIndex, exercises.length, handleNext, speakSentence, isSpeakingComplete, dynamicPassingScore, determineTtsInfo]);

  // Kullanıcı egzersizden ayrıldığında servise bilgi gönderiliyor.
  const addMemberSpeakingLab = () => {
    setQuitExerciseButtonLoading(true);

    AddMemberSpeakingLab({
      memberId: exerciseData?.memberId,
      dailyDate: exerciseData?.selectedDate,
      bookUnitId: exerciseData?.bookUnitId,
      exerciseSequence: exerciseData?.currentExerciseIndex + 1,
      speakingLabResultType: SpeakingLabResultType.CONTINUE
    }) 
      .then((result) => {
        if(result.status === 200) {
          closeModal(modalOverlayRef, modalRef);
          navigate(url("speakinglesson.dailylesson"));
        }

        setQuitExerciseButtonLoading(false);
      })
      .catch()
  }

  const handleClickCloseButton = () => {
    openModal(modalOverlayRef, modalRef);
    analytics.useAnalytics(EventLogs.SPEAKING_LAB_CLOSE);
    analytics.useAnalytics(
        EventLogs.SPEAKING_LAB_PROGRESS_MODAL,
        undefined,
        true,
        false
    );
  }

  // Egzersiz sayısını hesaplayan yardımcı fonksiyon ekleyelim
  const calculateExerciseProgress = useCallback(() => {
    // Card olmayan egzersizlerin sayısını bul
    const actualExerciseCount = exercises.filter(ex => !ex.isCard).length;
    
    // Mevcut egzersize kadar olan card olmayan egzersizlerin sayısını bul
    const currentActualExerciseCount = exercises
      .slice(0, exerciseData?.currentExerciseIndex + 1)
      .filter(ex => !ex.isCard)
      .length;

    return {
      current: currentActualExerciseCount,
      total: actualExerciseCount
    };
  }, [exercises, exerciseData?.currentExerciseIndex]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Egzersiz ilerleme durumunu gösterir */}
      <ExerciseQuestionStep 
        currentQuestion={calculateExerciseProgress().current} 
        totalQuestionCount={calculateExerciseProgress().total}
        closeButtonAction={handleClickCloseButton}
      />

      {/* Mevcut egzersizi render eder */}
      {getCurrentExercise() && renderExercise(getCurrentExercise())}

      <ModalOverlay ref={modalOverlayRef} />
      <ModalTwoButtons
        ref={modalRef}
        overlayRef={modalOverlayRef}
        title={strings.modal.transaction_information}
        message={strings.speaking_lesson.speaking_lab.exit_exercise_question_text}
        disableText={strings.general_information_messages.processing}
        buttonText1={strings.modal.approve}
        isDisableButtonOne={quitExerciseButtonLoading}
        buttonClick1={() => addMemberSpeakingLab()}
        buttonText2={strings.modal.dont_approve}
        buttonClick2={() => closeModal(modalOverlayRef, modalRef)}
      />
    </>
  );
};

export default SpeakingLabFlow;