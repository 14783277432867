import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BottomStickyBar, Button } from "../../../../../../components";
import useAuth from "../../../../../../hooks/useAuth";
import { url } from "../../../../../../routes/utility";

const ReferenceBar = ({ stickyBar }) => {

    const navigate = useNavigate();

    const { member } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    const giftUrl = `https://www.azeringilisce.com/gift/?q=${btoa(`m=${member?.MemberId}`)}`;

    return (
        <BottomStickyBar
            theme={stickyBar.theme}
        >
            <div className="flex justify-between items-center min-h-[48px]">
                <p className="font-bold text-[16px] md:text-[18px]">
                    {stickyBar.title[language]}
                </p>
                <Button
                    classnames="button primary-button-outline !border-onboarding-border bg-box text-base-text px-4 h-8 max-w-max text-[16px] mt-0 leading-[16px]"
                    text={stickyBar.buttonText[language]}
                    action={() => {
                        if(stickyBar.buttonType === "button") {
                            navigate(url(stickyBar.buttonPath));
                        } else if(stickyBar.buttonType === "gift") {
                            window.open(giftUrl, "_blank");
                        } else {
                            window.open(stickyBar.buttonPath, "_blank");
                        }
                    }}
                />
            </div>
        </BottomStickyBar>
    );
}

export default ReferenceBar;