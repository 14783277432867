import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocalization} from "../../../../hooks/useLocalization";
import classNames from "classnames";
import {Month1, Month10, Month3, Month6, Month9_12} from "../../../../assets/svg";
import {EventLogs, MemberStatus} from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import {formatPriceWithoutCurrency} from "../../../../utils";

const SelectPackage = (props) => {

    const {
        selectedCategory,
        setSelectedProduct,
        totalDiscountRate,
        memberInfo,
        pathInfo,
        handleChangeProduct,
        productList
    } = props;

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);
    const analytics = useAnalytics();

    const tripEnglishId = 8;

    // Servisten gelen dakikaların tutulduğu state.
    const [minuteList, setMinuteList] = useState([]);
    // Seçili gün bilgisinin tutulduğu state.
    const [selectedDay, setSelectedDay] = useState(0);
    // Seçili dakika bilgisinin tutulduğu state.
    const [selectedMinute, setSelectedMinute] = useState(0);
    // Seçili ay bilgisinin tutulduğu state.
    const [selectedMonth, setSelectedMonth] = useState({});

    // Ay bilgisine göre ikon getiren fonksiyon.
    const getMonthIconsByMonth = (month) => {
        switch (month) {
            case 1:
                return Month1;
            case 3:
                return Month3;
            case 4:
                return Month3;
            case 6:
                return Month6;
            case 8:
                return Month6;
            case 9:
                return Month9_12;
            case 10:
                return Month10;
            case 12:
                return Month9_12;
            default:
                return "";
        }
    }

    // Seçili kategoriye göre ürün seçenekleri servisten getiriliyor.
    useEffect(() => {
        if (selectedCategory) {
            setSelectedDay(selectedDay => 0);
            setSelectedMinute(selectedMinute => 0);
            setSelectedMonth(selectedMonth => { });
        }
    }, [selectedCategory, language, productList]);

    // Sayfaya ilk girildiğinde eğer pathtten gönderilen bir bilgi yoksa default seçimler yapıtılıyor.
    // Data'da bulunan ilk gün ve orta bir dakika set ediliyor.
    // Path'ten gönderilen Seç bilgisi yok ise bu işlem yapılıyor
    useEffect(() => {
        if(Object.keys(productList).length > 0 && !pathInfo) {
            const dayIndex = productList.productDayCountList.length - 1;
            // Gün listesinin en sonundaki set ediliyor.
            // Bunun sebebi haftada 2 günün otomatik seçili gelmesi istenmesidir.
            setSelectedDay(selectedDay => productList.productDayCountList[dayIndex]);
            
            // 12 ay otomatik seçili gelmesi sağlanıyor.
            const monthIndex = productList.productMonthList.length - 1;
            setSelectedMonth(selectedMonth => productList.productMonthList[monthIndex]);
        }
    }, [productList])

    // Seçili güne göre dakika listesi dolduruluyor.
    // Bu kodun nedeni; servisten maplenecek dataların doğru formatta gelmemesidir.
    // Bu işlem seçili güne göre yapılıyor.
    useEffect(() => {
        if(Object.keys(productList).length > 0 && selectedDay !== 0){
            let tempMinuteList = productList?.productMinuteListByDay?.find(day => day.dayCount === selectedDay)?.minuteList;
            setMinuteList(minuteList => tempMinuteList);
        }
    }, [selectedDay, productList])

    // Dakika listesine göre işlem gerçekleştirilerek, seçili dakika belirleniyor
    // Path'ten gönderilen Seç bilgisi yok ise bu işlem yapılıyor
    useEffect(() => {
        if(minuteList.length > 0 && !pathInfo) {
            if (selectedMinute === 0) {
                setSelectedMinute(selectedMinute => minuteList.includes(10) ? 10 : minuteList[0]);
            }
            if (minuteList.length < 4 && selectedMinute === 40) {
                setSelectedMinute(selectedMinute => minuteList.includes(30) ? 30 : minuteList[0]);
            }
        }
    }, [minuteList, pathInfo])

    // Path'ten gönderilen Seç bilgisi var ise gönderilen bilgiler set ediliyor.
    useEffect(() => {
        if(Object.keys(productList).length > 0 && pathInfo) {
            setSelectedDay(selectedDay => +pathInfo?.Day);
            setSelectedMinute(selectedMinute => +pathInfo?.Minute);

            const tempSelectedMonth = productList.productMonthList.find(p => p.month === +pathInfo?.Month);
            setSelectedMonth(selectedMonth => tempSelectedMonth);
        }
    }, [productList, pathInfo])

    // Seçili gün, dakika, ay bilgisine göre paket data içerisinden bulunuyor ve set ediliyor.
    useEffect(() => {
        if(Object.keys(productList).length > 0 &&
            selectedDay !== 0 &&
            selectedMinute !== 0 &&
            Object.keys(selectedMonth).length > 0
        ){
            let tempSelectedProduct = productList?.products.find(p =>
                p.dayCount === selectedDay &&
                p.minute === selectedMinute &&
                p.month === selectedMonth.month);

            // Hediye ay bilgisi ve ay id'si de seçili ürüne set ediliyor.
            tempSelectedProduct = {
                ...tempSelectedProduct,
                monthId: selectedMonth.monthId,
                giftMonth: selectedMonth.giftMonth,
                categoryId: selectedCategory.id
            };

            setSelectedProduct(tempSelectedProduct);
            handleChangeProduct({ tempSelectedProduct });
        }
    }, [selectedDay, selectedMinute, selectedMonth, productList]);

    return (
        Object.keys(productList).length > 0 && (
            <>
                <div className="transparent-section mt-5 text-sm">
                    {/* Day Listesi */}
                    <div className="flex flex-col">
                        <p className="font-bold mb-1 mt-2 text-start">{strings.market.speakingLesson.title1}</p>
                    </div>
                    <div className="flex justify-between mt-[6px] gap-3 md:gap-5">
                        {productList?.productDayCountList.map((day, index) => (
                            <button
                                key={index}
                                type="button"
                                className={classNames("market-button-square w-full h-[80px] md:h-[100px] max-w-[100px] md:max-w-[200px]", {
                                    "bg-onboarding-bg-select border-primary": selectedDay === day,
                                })}
                                onClick={() => {
                                    analytics.useAnalytics(EventLogs.MARKET_PACKAGE_OPTION_CHANGE);
                                    setSelectedDay(selectedDay => day)
                                }}
                            >
                                <p className="font-bold md:text-[14px] mt-2">{day} {strings.market.speakingLesson.day}</p>
                            </button>
                        ))}
                    </div>

                    {/* Minute Listesi */}
                    <div className="flex flex-col">
                        <p className="font-bold mb-1 mt-2 text-start">{strings.market.speakingLesson.title2}</p>

                        <div className="flex justify-between mt-[6px] gap-3 md:gap-5">
                            {minuteList.length > 0 &&
                                minuteList.map((minute, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={classNames("market-button-square w-full h-[80px] md:h-[100px] max-w-[100px] md:max-w-[200px]", {
                                            "bg-onboarding-bg-select border-primary": selectedMinute === minute,
                                            "md:w-[200px]": minuteList.length <= 3,
                                            "md:w-[150px]": minuteList.length > 3,
                                        })}
                                        onClick={() => {
                                            setSelectedMinute(selectedDay => minute);
                                            analytics.useAnalytics(EventLogs.MARKET_PACKAGE_OPTION_CHANGE);
                                        }}
                                    >
                                        <p className="font-bold md:text-[14px] mt-2">{minute} {strings.market.speakingLesson.minute}</p>
                                    </button>
                                ))
                            }
                        </div>
                    </div>

                    {/* Ay Listesi */}
                    <div className="flex flex-col">
                        <p className="font-bold mb-1 mt-2 text-start">{strings.market.speakingLesson.title3}</p>

                        <div className="flex justify-between mt-[6px] gap-3 md:gap-5">
                            {productList?.productMonthList?.map((monthInfo, index)=> {
                                // Paket listesi içerisinden seçili ay'a göre arama yapılarak, paket bulunuyor.
                                const selectedProduct = productList?.products.find(p =>
                                    p.dayCount === selectedDay &&
                                    p.minute === selectedMinute &&
                                    p.month === monthInfo.month
                                );

                                // Seçili paket içerisinde fiyat bilgisinin tutulduğu obje alınıyor.
                                const productPriceDetail = selectedProduct?.productDetail?.priceDetail;
                                const isSelectedProduct = selectedMonth?.month === monthInfo.month;
                                const productCandidatePriceDetail = selectedProduct?.productDetail?.candidatePriceDetail;
                                const isNotThreeMonthPackage = selectedProduct?.month !== 3 && productPriceDetail?.discountRate !== 0;

                                let discountLessonPrice;

                                if(isSelectedProduct && totalDiscountRate > 0){
                                    const discountTotalPrice = (selectedProduct?.productDetail?.priceDetail && (totalDiscountRate))
                                        ? (productPriceDetail?.totalPriceWithoutFormat * (1 - (totalDiscountRate * 0.01)))
                                        : 0;

                                    // Uygulanan indirim düşülmüş ders başı ücret
                                    discountLessonPrice = discountTotalPrice !== 0
                                        ? (discountTotalPrice / productPriceDetail?.totalLessonCount)
                                        : 0;
                                }

                                return (
                                    (monthInfo?.month !== 1 || (monthInfo?.month === 1 && selectedCategory.id === tripEnglishId)) && (
                                        <button
                                            key={index}
                                            type="button"
                                            className={classNames("market-button-square w-full h-[175px] max-w-[200px] relative overflow-hidden", {
                                                "bg-onboarding-bg-select border-primary": selectedMonth?.month === monthInfo.month
                                            })}
                                            onClick={() => {
                                                setSelectedMonth(selectedMonth => monthInfo);
                                                analytics.useAnalytics(EventLogs.MARKET_PACKAGE_OPTION_CHANGE);
                                            }}
                                        >
                                            {/* Basılan paket 3 aylık değilse ve base indirimi var ise indirim bilgisi basılıyor. */}
                                            {isNotThreeMonthPackage &&
                                                <p className="bg-secondary text-white text-[10px] md:text-[14px] xs-md:text-[12px] absolute w-full py-1 top-0">
                                                    %{productPriceDetail?.discountRate} {strings.market.speakingLesson.discount}
                                                </p>
                                            }

                                            <img
                                                className="h-[45px] w-[45px] mb-3 mt-7"
                                                src={getMonthIconsByMonth(monthInfo.month)}
                                                alt="month-icon"
                                            />

                                            {/* Ay ve var ise hediye ay bilgisi basılıyor */}
                                            <p className="font-bold text-[10px] md:text-[14px] xs-md:text-[12px] mt-2 mb-2">
                                                {monthInfo.month}{" "}
                                                {strings.market.speakingLesson.month}
                                                {monthInfo.giftMonth == 1 && " + " + monthInfo.giftMonth + " " + strings.market.speakingLesson.month_single}
                                                {monthInfo.giftMonth > 1 && " + " + monthInfo.giftMonth + " " + strings.market.speakingLesson.month}
                                            </p>

                                            {/* 3 Aylık paketler dışında olan paketlerde indirimsiz fiyat basılıyor */}
                                            {isNotThreeMonthPackage && (
                                                <div className="py-1 relative">
                                                    <span className='md:text-[16px] xs-md:text-[12px] text-[10px] font-bold'>
                                                        {(MemberStatus.Active === +memberInfo?.Status || MemberStatus.Passive === +memberInfo?.Status) &&
                                                            productCandidatePriceDetail?.lessonPrice
                                                        }

                                                        {MemberStatus.Candidate === +memberInfo?.Status &&
                                                            productPriceDetail?.lessonPrice
                                                        }

                                                        {productPriceDetail?.currencySymbol}
                                                    </span>
                                                    <div className='absolute h-0.5 w-full inset-0 bg-base-text my-auto'></div>
                                                </div>
                                            )}
                                            <p className="text-primary md:text-[16px] xs-md:text-[12px] text-[10px] font-bold py-1">
                                                {isSelectedProduct && totalDiscountRate > 0
                                                    ? formatPriceWithoutCurrency(discountLessonPrice)
                                                    : productPriceDetail?.discountLessonPrice
                                                }

                                                {" "}{productPriceDetail?.currencySymbol}
                                            </p>
                                        </button>
                                ))
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default SelectPackage;